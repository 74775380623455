import React from 'react';
import { graphql, withPrefix, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Call from '../components/Call';

import PostSlices from '../components/PostSlices';
const Posts = (props) => {
  const posts = props.data.allPrismicPost.edges;

  return (
    <Layout bodyClass="page-posts">
      <SEO title="Posts"/>
      <Helmet>
        <meta
          name="description"
          content="Personal training, fitness, Muscle Activation and general nutrition site. Business located in Beaverton, OR."
        />
      </Helmet>
      <div className="container pt-8 pt-md-10">
        <div className="row justify-content-start box-shadow ">
          <div className="col-12 ">
            <h2 className="title-3 text-dark mb-3">Posts</h2>
          </div>
          {posts.map(edge => (
            <>
            <div key={edge.node.uid} className="col-12 ">
              <div className="">
                <div className="post-meta">
                  <h1 className="text-center post-title">
                    <Link
                    to={"/posts/" + edge.node.uid}>{edge.node.data.title.text}
                    </Link>
                  </h1>
                  <PostSlices allSlices={edge.node.data.body}/>
                </div>
                <div
                  className="mt-3 pr-3">
                  <Link className="btn btn-outline-secondary"
                    to={"/posts/" + edge.node.uid}>link to post</Link>
                </div>
              </div>
            </div>

            <div className="w-100 m-5 border-bottom"/>
            </>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
query PostsQuery {
  allPrismicPost(
    filter: {data: {categories: {elemMatch: {category: {type: {ne: "promo"}}}}, hidden: {ne: true}}}
    sort: {order: DESC, fields: data___date}
  ) {
    edges {
      node {
        id
        uid
        data {
          title {
            text
          }
          categories {
            category {
              slug
            }
          }
          body {
            __typename
            ... on PrismicPostDataBodyCodeBlock {
              slice_type
              id
              primary {
                code_block {
                  raw
                  html
                }
              }
            }
            ... on PrismicPostDataBodyImage {
              slice_type
              id
              primary {
                image {
                  gatsbyImageData
                  url
                }
              }
            }
            ... on PrismicPostDataBodyImageCarousel {
              slice_type
              id
              items {
                image {
                  url
                }
                caption {
                  text
                }
                label {
                  html
                }
              }
            }
            ... on PrismicPostDataBodyContact {
              id
              slice_type
              primary {
                email
                form_name
                message
                name
              }
            }
            ... on PrismicPostDataBodyContentBlock {
              id
              slice_type
              primary {
                image {
                  gatsbyImageData
                  url
                  alt
                }
                image_location
                text {
                  html
                  raw
                }
              }
            }
            ... on PrismicPostDataBodyYoutubevideo {
              id
              slice_type
              primary {
                url {
                  url
                }
              }
            }
            ... on PrismicPostDataBodyJumbotron {
              id
              slice_type
              primary {
                title
                subheading
              }
            }
            ... on PrismicPostDataBodyHardReturn {
              slice_type
            }
            ... on PrismicPostDataBodyText {
              id
              slice_type
              primary {
                text {
                  html
                }
              }
            }
          }
          preview {
            text
          }
          date
        }
      }
    }
  }
}

`;

export default Posts;
